  import React, { useEffect, useState } from 'react';
  import { useParams, useHistory } from 'react-router-dom';
  import { sellsService } from '../../../services';
  import { Button } from '@material-ui/core';
  import './style.css';

  // Función de formateo de fecha
  const formatFecha = (fechaISO) => {
    if (!fechaISO) return ''; // Manejar caso de fecha nula o indefinida
    const fecha = new Date(fechaISO);
    return new Intl.DateTimeFormat('es-ES', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    }).format(fecha);
  };

  type Product = {
    id: string;
    name: string;
    sku: string;
  };
  
  type ProductCar = {
    idProduct: string;
    quantity: number;
    price: number;
    cost: number;
    sellerComission: number;
    collaboratorComission: number;
    shippingUnitCost: number;
    sku: string;
  };

  type Venta = {
    nroVenta: string;
    deliverydate: string;
    totalprice: number;
    totalcost: number;
    seller: {
      firstName: string;
      lastName: string;
    };
    client: {
      firstname: string;
      lastname: string;
      address: string;
      phone: string;
      locality: string;
      dni: string;
      beetwenstreets: string;
      comments: string;
    };
    product: Product[];
    productCar: ProductCar[];
  };
  
  const RemitoPage = () => {
    const { nroVenta } = useParams<{ nroVenta?: string }>();
    const [venta, setVenta] = useState<Venta | null>(null);
    const history = useHistory();

    useEffect(() => {
      const fetchVenta = async () => {
        try {
          const venta = await sellsService.find(nroVenta); // Usar el método correcto
          console.log('Venta:', venta);
          setVenta(venta);
        } catch (error) {
          console.error('Error fetching venta:', error);
        }
      };
  
      fetchVenta();
    }, [nroVenta]);

    const handleCancel = () => {
      history.goBack();
    };
  
    const handlePrint = () => {
      window.print();
    };

    const renderProductos = () => {
    if (!venta || !venta.product || !venta.productCar) {
      return <p>No hay productos en esta venta.</p>;
    }

    return venta.productCar.map((productoCar) => {
      const productInfo = venta.product.find(p => p.id === productoCar.idProduct);

      return (
        <tr key={productoCar.idProduct}>
          <td>{productInfo ? productInfo.sku : ''}</td>
          <td>{productInfo ? productInfo.name : 'Producto desconocido'}</td>
          <td>{productoCar.quantity}</td>
          <td></td>
        </tr>
      );
    });
  };
  
  return (
    <div className="remito-container">
      <div className="remito-header">
        <div className="remito-vendedor">
          <p><strong>Vendedor:</strong> {venta ? `${venta.seller.firstName} ${venta.seller.lastName}` : ''}</p>
        </div>
        <div className="remito-info">
          <h2>{venta ? decodeURIComponent(venta.client.locality) : ''}</h2>
          <p><strong>Fecha:</strong> {venta ? formatFecha(venta.deliverydate) : ''}</p>
          <p><strong>N° Pedido:</strong> {venta ? venta.nroVenta : ''}</p>
        </div>
      </div>
      <div className="remito-cliente">
        <p><strong>Nombre:</strong> {venta ? decodeURIComponent(`${venta.client.firstname} ${venta.client.lastname}`) : ''}<span className="remito-telefono"><strong>DNI:</strong> {venta ? venta.client.dni : ''}</span></p>
        <p><strong>Teléfono:</strong> {venta ? venta.client.phone : ''}</p>
        <p><strong>Dirección:</strong> {venta ? decodeURIComponent(venta.client.address) : ''}</p>   
        <p><strong>Entre Calles:</strong> {venta ? decodeURIComponent(venta.client.beetwenstreets) : ''}</p>        
      </div>
      <table className="remito-productos">
        <thead>
          <tr>
            <th>SKU</th>
            <th>Artículo</th>
            <th>Cant</th>
            <th>Precio total</th>
          </tr>
        </thead>
        <tbody>
          {renderProductos()}
        </tbody>
      </table>
      <div className="remito-comentarios">
          <p><strong>{venta ? decodeURIComponent(venta.client.comments) : ''}</strong></p> 
        </div>
      <div className="remito-right">
        <div className="remito-total">
          <p><strong>Total:</strong> $ {venta ? venta.totalcost : 0}</p> 
        </div>
      </div>
      <div className="remito-duplicado">
        <div className="remito-header">
          <div className="remito-vendedor">
            <p><strong>Vendedor:</strong> {venta ? `${venta.seller.firstName} ${venta.seller.lastName}` : ''}</p>
          </div>
          <div className="remito-info">
            <h2>{venta ? decodeURIComponent(venta.client.locality) : ''}</h2>
            <p><strong>Fecha:</strong> {venta ? formatFecha(venta.deliverydate) : ''}</p>
            <p><strong>N° Pedido:</strong> {venta ? venta.nroVenta : ''}</p>
          </div>
        </div>
        <div className="remito-cliente">
          <p><strong>Nombre:</strong> {venta ? decodeURIComponent(`${venta.client.firstname} ${venta.client.lastname}`) : ''}<span className="remito-telefono"><strong>DNI:</strong> {venta ? venta.client.dni : ''}</span></p>
          <p><strong>Teléfono:</strong> {venta ? venta.client.phone : ''}</p>
          <p><strong>Dirección:</strong> {venta ? decodeURIComponent(venta.client.address) : ''}</p>   
          <p><strong>Entre Calles:</strong> {venta ? decodeURIComponent(venta.client.beetwenstreets) : ''}</p>        
        </div>
        <table className="remito-productos">
          <thead>
            <tr>
              <th>SKU</th>
              <th>Artículo</th>
              <th>Cant</th>
              <th>Precio total</th>
            </tr>
          </thead>
          <tbody>
            {renderProductos()}
          </tbody>
        </table>
        <div className="remito-right">
          <div className="remito-total">
            <p><strong>Total:</strong> $ {venta ? venta.totalcost : 0}</p> 
          </div>
        </div>
      </div>
      <div className="remito-footer">
        <Button variant="contained" color="secondary" onClick={handleCancel} style={{ marginRight: '10px' }}>
          Cancelar
        </Button>
        <Button variant="contained" color="primary" onClick={handlePrint}>
          Imprimir
        </Button>
      </div>
    </div>
  );
};
  
  export default RemitoPage;
  