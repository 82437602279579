import { useState, useEffect, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { AuthContext } from '../../../App';
import { decode_utf8, useNotifications } from '../../../hooks';
import { sellsService, usersService } from '../../../services';
import { GridColDef } from '@material-ui/data-grid';
import { Grid, Button, createStyles, makeStyles, Theme } from '@material-ui/core';
import { DefaultGrid } from '../../../components';
import {
  zones,
  buyerTypes,
  sellSatus,
  deliveryStatus,
  DELIVERY_STATUSES,
} from '../../../constants';
import { SelectionAction } from '../../../components';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginRight: theme.spacing(1),
    },
  })
);

function fullName(obj: any): string {
  if (!obj) return 'Desconosido';

  if (obj.firstname && obj.lastname) {
    obj.firstName = obj.firstname;
    obj.lastName = obj.lastname;
  }

  return [obj.firstName, obj.lastName].filter(Boolean).join(' ');
}

function formatDeliveryMans(data: any) {
  return data.map((x: any) => ({
    value: x.id,
    description: `${x.firstName} ${x.lastName}`,
  }));
}

const getColumns = (sell:any): GridColDef[] => [
  { field: 'sku', headerName: 'SKU', width: 150 },
  { field: 'name', headerName: 'Nombre', width: 350 },
  { field: 'category', headerName: 'Categoría', valueGetter: ({ row }) => row.category?.name || '' },
  { field: 'subCategory', headerName: 'Sub-categoría', valueGetter: ({ row }) => row.subCategory?.name || '' },
  { field: 'fullPrice', headerName: 'Precio', valueGetter: ({row}) => sell.productCar.find((pc: any)=>pc.idProduct===row.id)?.price || (sell.client.typeclient===1 ? row.retailPrice : row.fullPrice) },
  { field: 'stock', headerName: 'Cantidad', valueGetter: ({row}) => sell.productCar.find((pc: any)=>pc.idProduct===row.id)?.quantity || 0 },
];

const SellsDetails = () => {
  const { id } = useParams<{ id?: string }>();
  const { showNotification } = useNotifications();
  const [loading, setLoading] = useState<boolean>(false);
  const { userState } = useContext(AuthContext);
  const [sell, setSell] = useState<any>({});
  const [deliveryMans, setDeliveryMans] = useState<any>([]);
  const showCollaborator = ['admin', 'deposit'].includes(userState.type);
  const classes = useStyles();
  const history = useHistory();

  const getSell = async () => {
    setLoading(true);
    try {
      const response = await sellsService.find(id);
      const responseDeliveryMans = await usersService.getDeliverymans();

      setSell(response);
      setDeliveryMans(responseDeliveryMans);
    } catch (e) {
      showNotification(
        'error',
        sellsService.getMessages('GET_COLLABORATORS', e).error
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getSell();
  }, []);
  
  const handleBack = () => {
    history.goBack();
  };

  if (loading) return <div>loading...</div>;

  const {
    client = {},
    seller = {},
    product,
    collaborator = {},
    deliveryMan = {},
  } = sell;

  const isDelivered = DELIVERY_STATUSES.delivered === sell.deliverystate;

  const handleAssignmentConfirmation = async (deliveryManId: any) => {
    try {
      await sellsService.assignDeliveryMan(sell.nroVenta, deliveryManId);
      showNotification('success', 'Repartidor asignado correctamente');
      setSell({
        ...sell,
        deliveryMan: deliveryMans.find((x: any) => x.id === deliveryManId),
      });
    } catch {
      showNotification(
        'error',
        'Se produjo un error al intentar asignar al repartidor'
      );
    }
  };

  return (
    <div>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <Button onClick={handleBack} className={classes.button} type="button">
            Volver
        </Button>
        <h1>No Venta {sell.nroVenta}</h1>
        <div>
          {sell && userState.type === 'admin' && !isDelivered && sell.zone===5 && (
            <SelectionAction
              tooltip="Asignar repartidor"
              handleSelection={handleAssignmentConfirmation}
              message="Seleccione un repartidor"
              options={formatDeliveryMans(deliveryMans)}
              children={
                <Button variant="outlined" color="primary" type="button">
                  Asignar Repartidor
                </Button>
              }
            />
          )}
        </div>
      </Grid>
      {showCollaborator && <h3>Collaborator: {fullName(collaborator)}</h3>}
      {product && (
        <DefaultGrid
          rows={product}
          columns={getColumns(sell)}
          pageSize={5}
          height={300}
        />
      )}

      <br />
      <ul>
        <li>Precio total de la venta: {sell.totalcost}</li>
        <li>Comisión vendedor: {sell.sellercomission}</li>
        <li>Comisión colaborador: {sell.colaboradorcomission}</li>
        <li>
          Fecha de venta: {new Date(sell.saledate).toLocaleDateString('es-ES')}
        </li>
        <li>Vendedor: {decode_utf8(fullName(seller))}</li>
      </ul>

      <h3>Datos del comprador</h3>
      <ul>
        <li>Nombre: {decode_utf8(fullName(sell.client))}</li>
        <li>DNI : {client.dni}</li>
        <li>
          Dirección de envío: ubicación de Google Map, piso, depto. :{' '}
          {decode_utf8(client.address)}
        </li>
        <li>Entre calles : {decode_utf8(client.beetwenstreets)}</li>
        <li>Zona : {zones[client.zone]}</li>
        <li>Comentarios : {decode_utf8(client.comments)}</li>
        <li>Email : {client.email}</li>
        <li>Teléfono : {client.phone}</li>
        <li>Tipo comprador : {buyerTypes[client.typeclient]}</li>
      </ul>

      <h3>Datos de la venta</h3>
      <ul>
        <li>Estado de venta: {sellSatus[sell.salestate]}</li>
        <li>Estado de entrega: {deliveryStatus[sell.deliverystate]} </li>
        <li>Repartidor: {decode_utf8(fullName(deliveryMan))}</li>
        <li>Comentarios : {decode_utf8(sell.comments)}</li>
      </ul>
    </div>
  );
};

export default SellsDetails;
