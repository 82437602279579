import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { LoadingIndicator, FormActions } from '../../../components';
import FileUpload from '../../../components/FileUpload'; 
import { useCRUD, useNotifications } from '../../../hooks';
import { productsService, categoriesService } from '../../../services';
import { Button, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';

const ProductsUpdate = ({ mode }: { mode: string }) => {
  const title = 'Actualización masiva';
  const history = useHistory();
  const { showNotification } = useNotifications();

  const [previewData, setPreviewData] = useState<any[]>([]);
  const [categoriesAndSubcategories, setCategoriesAndSubcategories] = useState<any[]>([]);

  const { handleAdd, handleEdit, loading } = useCRUD(
    null,
    productsService.add,
    productsService.edit,
    productsService.remove,
    showNotification,
    productsService.getMessages
  );

  // Cargar categorías y subcategorías al iniciar
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const categories = await categoriesService.getCategoriesAndSubCategories();
        setCategoriesAndSubcategories(categories);
      } catch (error) {
        console.error('Error al cargar las categorías:', error);
        showNotification('error', 'Error al cargar categorías y subcategorías');
      }
    };
    fetchCategories();
  }, [showNotification]);

  const formik = useFormik({
    initialValues: {},
    onSubmit: async () => {
      try {
        const failedUpdates: number[] = [];
        const failedCreates: number[] = [];
  
        for (const product of previewData) {
          try {
            const productDTO = mapCsvToDTO(product);
  
            // Si el ID es 0 o null, crear el producto; de lo contrario, actualizarlo
            if (productDTO.Id === null) {
              await handleAdd(productDTO); // Método para crear productos nuevos
            } else {
              await handleEdit(productDTO); // Método para actualizar productos existentes
            }
          } catch (error) {
            console.error(
              `Error al procesar el producto con ID ${
                product.ID || 'nuevo'
              }:`,
              error
            );
  
            // Agregar a diferentes listas de errores según el caso
            if (product.ID && product.ID > 0) {
              failedUpdates.push(product.ID);
            } else {
              failedCreates.push(product.Sku || 'Desconocido'); // Agregar Sku para productos nuevos
            }
          }
        }
  
        // Mostrar notificaciones de errores
        if (failedUpdates.length > 0 || failedCreates.length > 0) {
          showNotification(
            'error',
            `Errores:
              ${failedUpdates.length > 0 ? `Actualizaciones fallidas: ${failedUpdates.join(', ')}` : ''}
              ${failedCreates.length > 0 ? `Creaciones fallidas (SKU): ${failedCreates.join(', ')}` : ''}`
          );
        } else {
          showNotification('success', 'Cambios aplicados correctamente');
        }
  
        history.goBack();
      } catch (error) {
        console.error('Error general al aplicar los cambios:', error);
        showNotification('error', 'Error al aplicar los cambios');
      }
    },
  });
  

  // Definir el tipo para los datos del CSV
  type CsvRow = {
    ID: string; // ID será un string porque proviene de un CSV
    SKU: string;
    [key: string]: any; // Permitir otras propiedades dinámicas
  };

  // Procesar el archivo CSV
  const handleFileUpload = async (file: File) => {
    try {
      console.log("Iniciando carga de archivo...");
      if (!file.name.endsWith('.csv')) {
        throw new Error('Formato de archivo no válido. Solo se aceptan archivos .csv.');
      }

      const text = await file.text();
      // Cambiar el delimitador de ',' a ';' y evitar manipulaciones innecesarias
      const rows = text.split('\n').map((row) => row.split(';'));

      const [headers, ...data] = rows;

      // Validar que haya encabezados y datos
      if (!headers || headers.length === 0) {
        throw new Error('El archivo no contiene encabezados válidos.');
      }
      if (!data || data.length === 0) {
        throw new Error('El archivo no contiene datos.');
      }

      // Crear objetos JSON a partir de los encabezados y filas
      const jsonData: CsvRow[] = data.map((row) =>
        row.reduce((acc: CsvRow, value, index) => {
          acc[headers[index]?.trim()] = value?.trim();
          return acc;
        }, {} as CsvRow) // Cast a CsvRow
      );

      const validData = jsonData
      .filter(row => row.ID && parseInt(row.ID, 10) >= 0) // Filtrar filas con ID mayor a 0
      .map((row: any) => {
        const category = categoriesAndSubcategories.find(
          (cat: any) => cat.name.toLowerCase() === row['CATEGORIA']?.toLowerCase()
        );

        const subCategory = category?.subcategories.find(
          (sub: any) => sub.name.toLowerCase() === row['SUB-CATEGORIA']?.toLowerCase()
        );

        return {
          ID: row.ID || null,
          SKU: row.SKU || '',
          EAN: row.EAN || '',
          Nombre: row.NOMBRE || '',
          "Categoría": row["CATEGORIA"] || '',
          "Sub-categoría": row["SUB-CATEGORIA"] || '',
          "Precio (Min.)": parseFloat(row["PRECIO (MIN.)"] || 0),
          "Comisión de colaborador (Vent. Min.)": parseFloat(row["COMISION DE COLABORADOR (VENT. MIN.)"] || 0),
          "Comisión de vendedor (Vent. Min.)": parseFloat(row["COMISION DE VENDEDOR (VENT. MIN.)"] || 0),
          "Precio (May.)": parseFloat(row["PRECIO (MAY.)"] || 0),
          "Comisión de colaborador (Vent. May.)": parseFloat(row["COMISION DE COLABORADOR (VENT. MAY.)"] || 0),
          "Comisión de vendedor (Vent. May.)": parseFloat(row["COMISION DE VENDEDOR (VENT. MAY.)"] || 0),
          "Precio (May. 2)": parseFloat(row["PRECIO (MAY. 2)"] || 0),
          "Comisión de colaborador (Vent. May. 2)": parseFloat(row["COMISION DE COLABORADOR (VENT. MAY. 2)"] || 0),
          "Comisión de vendedor (Vent. May. 2)": parseFloat(row["COMISION DE VENDEDOR (VENT. MAY. 2)"] || 0),
          Stock: parseInt(row.STOCK || 0, 10),
          "Precio Redes": parseFloat(row["PRECIO REDES"] || 0),
          "Comisión de colaborador (Redes)": parseFloat(row["COMISION DE COLABORADOR (REDES)"] || 0),
          "Comisión de vendedor (Redes)": parseFloat(row["COMISION DE VENDEDOR (REDES)"] || 0),
          "Stock Redes": parseInt(row["STOCK REDES"] || 0, 10),
          "Costo Unit. Envío": parseFloat(row["COSTO UNIT. ENVIO"] || 0),
          Costo: parseFloat(row.COSTO || 0),
          idCategory: category?.id || null,
          idSubCategory: subCategory?.id || null,
          Description: row.SKU || '', // Descripción igual al SKU
        };
      });

      setPreviewData(validData);
      showNotification('success', 'Archivo CSV cargado y procesado correctamente.');
    } catch (error) {
      console.error("Error al cargar y procesar el archivo:", error.message);
      showNotification('error', error.message || 'Error al procesar el archivo CSV.');
    }
  };


  const mapCsvToDTO = (product: any) => {
    console.log("Producto procesado:", product);
  
    const isNewProduct = !product.ID || parseInt(product.ID, 10) === 0;
  
    const productDTO: any = {
      Sku: product.SKU,
      Ean: product.EAN,
      Name: product.Nombre,
      Description: product.SKU, // Descripción igual al SKU
      idCategory: product.idCategory,
      idSubCategory: product.idSubCategory,
      retailPrice: parseFloat(product["Precio (Min.)"] || 0),
      collaboratorCommissionRetail: parseFloat(product["Comisión de colaborador (Vent. Min.)"] || 0),
      sellerCommissionRetail: parseFloat(product["Comisión de vendedor (Vent. Min.)"] || 0),
      fullPrice: parseFloat(product["Precio (May.)"] || 0),
      collaboratorCommissionFull: parseFloat(product["Comisión de colaborador (Vent. May.)"] || 0),
      sellerCommissionFull: parseFloat(product["Comisión de vendedor (Vent. May.)"] || 0),
      fullPrice2: parseFloat(product["Precio (May. 2)"] || 0),
      collaboratorCommissionFull2: parseFloat(product["Comisión de colaborador (Vent. May. 2)"] || 0),
      sellerCommissionFull2: parseFloat(product["Comisión de vendedor (Vent. May. 2)"] || 0),
      Stock: parseInt(product.Stock || 0, 10),
      gridPrice: parseFloat(product["Precio Redes"] || 0),
      collaboratorCommissionGrid: parseFloat(product["Comisión de colaborador (Redes)"] || 0),
      sellerCommissionGrid: parseFloat(product["Comisión de vendedor (Redes)"] || 0),
      gridStock: parseInt(product["Stock Redes"] || 0, 10),
      shippingUnitCost: parseFloat(product["Costo Unit. Envío"] || 0),
      Cost: parseFloat(product.Costo || 0),
    };
  
    if (!isNewProduct) {
      productDTO.Id = parseInt(product.ID, 10); // Agregar `Id` solo si es un producto existente
    }
  
    return productDTO;
  };
  

  return (
    <>
      <LoadingIndicator loading={loading} />
      <h1>{title}</h1>
      <form onSubmit={formik.handleSubmit}>
        <FileUpload onFileUpload={handleFileUpload} />
        {previewData.length > 0 && (
          <>
            <h2>Vista previa de los cambios</h2>
            <Table>
              <TableHead>
                <TableRow>
                  {Object.keys(previewData[0])
                    .filter((key) => !['idCategory', 'idSubCategory', 'Description'].includes(key)) // Excluir las columnas
                    .map((key) => (
                      <TableCell key={key}>{key}</TableCell>
                    ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {previewData.map((row, index) => (
                  <TableRow key={index}>
                    {Object.entries(row)
                      .filter(([key]) => !['idCategory', 'idSubCategory', 'Description'].includes(key)) // Excluir las columnas
                      .map(([key, value], i) => (
                        <TableCell key={i}>{String(value)}</TableCell>
                      ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </>
        )}
        <FormActions baseUrl="/productos" />
        <Button type="submit" color="primary" variant="contained" disabled={loading}>
          Aplicar cambios
        </Button>
      </form>
    </>
  );
};

export default ProductsUpdate;
