import { GridCellParams, GridColDef } from '@material-ui/data-grid';
import { Link as RouterLink } from 'react-router-dom';
import { GridActions, SelectionAction } from '../../../components';
import { Dialog, IconButton, Link as MaterialLink, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import PrintIcon from '@material-ui/icons/Print';
import ApproveAndCancelActions from './ApproveAndCancelActions';
import LabelImportantIcon from '@material-ui/icons/LabelImportant';
import { SelectOptionType } from '../../../types';
import {
  deliveryStatusOptions,
  sellStatusOptions,
  zoneOptions,
  SELL_STATUSES,
} from '../../sells/form/options';
import { DELIVERY_STATUSES } from '../../../constants';
import { buyerTypeOptions } from '../../sells/form/options';
import { format } from 'date-fns';
import { RowBody } from '../../../components/grid/CustomGrid/types';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Collapse from '@material-ui/core/Collapse';
import DeliveryStateAction from './DeliveryStateAction';
import {colorOptions} from '../../users/form/options';
import { decode_utf8 } from '../../../hooks';

type Params = {
  onEdit: (x: GridCellParams) => void;
  onDelete: (x: GridCellParams) => void;
  onCancel: (x: GridCellParams) => void;
  onApprove: (x: GridCellParams) => void;
  onAssignDeliveryMan: (sellNumber: number, deliveryManId: number) => void;
  deliveryManOptions: SelectOptionType[];
  userType: string;
};

const getColumns = ({
  onEdit,
  onDelete,
  onApprove,
  onCancel,
  onAssignDeliveryMan,
  deliveryManOptions,
  userType,
}: Params): GridColDef[] => [
  { field: 'nroVenta', headerName: 'Número', width: 100 },
  {
    field: 'seller',
    headerName: 'Vendedor',
    width: 150,
    valueGetter: ({ row }) => `${row.seller.firstName} ${row.seller.lastName}`,
  },
  {
    field: 'deliverydate',
    headerName: 'Fecha de Entrega',
    width: 150,
    valueGetter: ({ row }) => format(new Date(row.deliverydate), 'MM/dd/yyyy'),
  },
  {
    field: 'location',
    headerName: 'Localidad',
    width: 250,
    valueGetter: ({ row }) => row.client.locality,
  },
  {
    field: 'zone',
    headerName: 'Zona',
    width: 120,
    valueGetter: ({ row }) =>
      zoneOptions.find((x) => x.value === row.zone)?.label || 'Desconocido',
  },
  {
    field: 'status',
    headerName: 'Estado de Venta',
    width: 150,
    valueGetter: ({ row }) =>
      sellStatusOptions.find((x) => x.value === row.salestate)?.label ||
      'Desconocido',
  },
  {
    field: 'deliveryStatus',
    headerName: 'Estado de Entrega',
    width: 200,
    valueGetter: ({ row }) =>
      deliveryStatusOptions.find((x) => x.value === row.deliverystate)?.label ||
      'Desconocido',
  },
  {
    field: 'deliveryMan',
    headerName: 'Repartidor',
    width: 150,
    valueGetter: ({ row }) =>
      row?.deliveryMan?.firstName
        ? `${row.deliveryMan.firstName} ${row.deliveryMan.lastName}`
        : 'Sin asignar',
  },
  {
    field: 'actions',
    filterable: false,
    headerName: 'Acciones',
    width: 300,
    renderCell: (params) => {
      const handleAssignmentConfirmation = (deliveryManId: any) => {
        onAssignDeliveryMan(params.row.nroVenta, deliveryManId);
      };
      const isDelivered =
        DELIVERY_STATUSES.delivered === params.row.deliverystate;
      const isApproved = params.row.salestate === SELL_STATUSES.approved;
      const isAdmin = userType === 'admin';
      const isCollaborator = userType === 'collaborator';

      return (
        <div
          style={{
            display: 'flex',
            justifyContent: isAdmin ? 'flex-end' : 'flex-center',
            width: '100%',
          }}
        >
          {['admin', 'deposit'].includes(userType) &&
            !isDelivered &&
            params.row.zone!==5 &&
            isApproved && (
              <SelectionAction
                icon={<LabelImportantIcon />}
                tooltip="Asignar repartidor"
                handleSelection={handleAssignmentConfirmation}
                message="Seleccione un repartidor"
                options={deliveryManOptions}
              />
            )}
          {userType !== 'deposit' && userType !== 'seller' && (
            <ApproveAndCancelActions
              params={params}
              onCancel={onCancel}
              onApprove={onApprove}
            />
          )}
          <MaterialLink
            component={RouterLink}
            to={`/ventas/${params.row.id}/detalle`}
          >
            <IconButton aria-label="view">
              <VisibilityIcon />
            </IconButton>
          </MaterialLink>
          {(isAdmin || isCollaborator) && (
            <GridActions
              params={params}
              handleEdit={(isAdmin && (3 !== params.row.deliverystate)) ? onEdit : null}
              handleDelete={(2 !== params.row.deliverystate) ? null : onDelete}
            />
          )}
        </div>
      );
    },
  },
];

export default getColumns;

export function GetDataBody({rows,
  onEdit,
  onDelete,
  onApprove,
  onCancel,
  onAssignDeliveryMan,
  deliveryManOptions,
  userType,
  setOpen,
  onDeliveryStatus
}:any) {
  let dataBody: RowBody[] = [];
  
  const getAccions = (row:any) => {
    const handleAssignmentConfirmation = (deliveryManId: any) => {
      onAssignDeliveryMan(row.nroVenta, deliveryManId);
    };
    const isDelivered =
      DELIVERY_STATUSES.delivered === row.deliverystate;
    const isApproved = row.salestate === SELL_STATUSES.approved;
    const isAdmin = userType === 'admin';
    const isCollaborator = userType === 'collaborator';

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: isAdmin ? 'flex-end' : 'flex-center',
          width: '100%',
        }}
      >
        {['admin', 'deposit'].includes(userType) &&
          !isDelivered &&
          row.zone!==5 &&
          isApproved && (
            <SelectionAction
              icon={<LabelImportantIcon />}
              tooltip="Asignar repartidor"
              handleSelection={handleAssignmentConfirmation}
              message="Seleccione un repartidor"
              options={deliveryManOptions}
            />
          )}
        {!isDelivered && userType !== 'deposit' && userType !== 'seller' && (
          <ApproveAndCancelActions
            params={{row}}
            onCancel={onCancel}
            onApprove={onApprove}
          />
        )}
        {!isDelivered && (userType === 'deposit' || userType === 'admin') &&
        row.zone===5 && 
        row.salestate === SELL_STATUSES.approved && (
          <DeliveryStateAction
            params={{row}}
            onDelivery={onDeliveryStatus}
          />
        )}
        <MaterialLink
          component={RouterLink}
          to={`/ventas/${row.nroVenta}/detalle`}
        >
          <IconButton aria-label="view">
            <VisibilityIcon />
          </IconButton>
        </MaterialLink>
        {!isDelivered && (isAdmin || isCollaborator) && (
          <GridActions
            params={{row}}
            handleEdit={(isAdmin && (3 !== row.deliverystate)) ? onEdit : null}
            handleDelete={(2 !== row.deliverystate) ? null : onDelete}
          />
        )}
        <MaterialLink
          component={RouterLink}
          to={`/ventas/${row.nroVenta}/remito`}
        >
        <IconButton aria-label="print">
          <PrintIcon />
        </IconButton>
        </MaterialLink>
      </div>
    );
  }


  function getProducts(row:any): any {
    return <Collapse in={row.open} timeout="auto" unmountOnExit>
        <Table size="small" aria-label="products">
          <TableHead>
            <TableRow>
              <TableCell>id</TableCell>
              <TableCell>nombre</TableCell>
              <TableCell>cantidad</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {row.product.map((productRow: any) => (
              <TableRow key={productRow.id}>
                <TableCell component="th" scope="row">
                  {productRow.id}
                </TableCell>
                <TableCell>{productRow.name}</TableCell>
                <TableCell>{row.productCar.find((p:any)=>p.idProduct===productRow.id).quantity}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
    </Collapse>;
  }

  function GetRow(row:any): any[] {
    return [
    {
      cells: [
        {key: 'numero', field:row.nroVenta, tableCellProps: {component:"th", scope:"row", className:"stickySaeta"}},
        {key: 'vendedor', field:`${row.seller.firstName} ${row.seller.lastName}`},
        {key: 'client', field:decode_utf8(`${row.client.firstname} ${row.client.lastname}`)},
        {key: 'fechaEntrega', field:format(new Date(row.deliverydate), 'dd/MM/yyyy')},
        {key: 'localidad', field:decode_utf8(row.client.locality)},
        {key: 'zona', field:decode_utf8(zoneOptions.find((x) => x.value === row.zone)?.label) || 'Desconocido'},
        {key: 'estadoVenta', field:sellStatusOptions.find((x) => x.value === row.salestate)?.label ||
          'Desconocido'},
        {key: 'estadoEntrega', field:deliveryStatusOptions.find((x) => x.value === row.deliverystate)?.label ||
          'Desconocido'},
        {key: 'repartidor', field:row?.deliveryMan?.firstName
          ? `${row.deliveryMan.firstName} ${row.deliveryMan.lastName}`
          : 'Sin asignar'},
        {key: 'productos', field: (<>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(row.nroVenta,!(row.open!==undefined ? row.open : false))}>
            {row.open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton><Dialog onClose={() => setOpen(row.nroVenta,!(row.open!==undefined ? row.open : false))} aria-labelledby="simple-dialog-title" open={row.open}>
          {getProducts(row)}
    </Dialog></>)},
        {key: 'actions', field:getAccions(row)}
      ],
      tableRowProps: {
        hover: true
      }
    }
  ]
  }

  userType!=='deposit' ?
  rows.map((row:any) => {
    row.nroVenta && GetRow(row).map((data: any)=>dataBody.push(data));
  })
  :
  rows.filter((row: any)=>row.zone===5).map((row:any) => {
    row.nroVenta && GetRow(row).map((data: any)=>dataBody.push(data));
  });
  return dataBody;
}

export const getDataHead = (userType:string) => {
  let dataHeadVentas = sessionStorage.getItem('dataHeadVentas');
  if (dataHeadVentas) {
    return JSON.parse(dataHeadVentas)
  }
  return dataHead(userType);
}

export const dataHead = (userType:string) => [
  {key: 'numero', field:"Número", tableCellProps: {align:"center", width: 40, className:"separador stickySaeta"}},
  {key: 'vendedor', field:"Vendedor", tableCellProps: {align:"center", width: 250,  className:"separador"}},
  {key: 'client', field:"Cliente", tableCellProps: {align:"center", width: 250, hidden:userType!=='deposit', className:"separador"}},
  {key: 'fechaEntrega', field:"Fecha de Entrega", tableCellProps: {align:"center", width: 180,  className:"separador"}},
  {key: 'localidad', field:"Localidad", tableCellProps: {align:"center", width: 250,  className:"separador"}},
  {key: 'zona', field:"Zona", tableCellProps: {align:"center", width: 150,  className:"separador"}},
  {key: 'estadoVenta', field:"Estado de Venta", tableCellProps: {align:"center", width: 120, hidden:true, className:"separador"}},
  {key: 'estadoEntrega', field:"Estado de Entrega", tableCellProps: {align:"center", width: 120,  className:"separador"}},
  {key: 'repartidor', field:"Repartidor", tableCellProps: {align:"center", width: 250,  className:"separador"}},
  {key: 'products', field: 'Productos', tableCellProps: {align:"center", width: 100,  className:"separador"}, hideFilterRow: true },
  {key: 'actions', field:"Acciones", tableCellProps: {align:"center"}}
];

export const headerExport: any[] = [
  {key: 'fechaVenta', label: 'Fecha de Venta'},
  {key: 'numero', label: 'Número'},
  {key: 'color', label: 'Grupo'},
  {key: 'vendedor', label: 'Vendedor'},
  {key: 'zona', label: 'Zona'},
  {key: 'localidad', label: 'Localidad'},
  {key: 'cliente', label: 'Cliente'},
  {key: 'dni', label: 'DNI'},
  {key: 'telefono', label: 'Telefono'},
  {key: 'direccion', label: 'Direccion'},
  {key: 'eCalles', label: 'E/Calles'},
  {key: 'sku', label: 'SKU'},
  {key: 'modelo', label: 'Modelo'},
  {key: 'cantidad', label: 'Cant'},
  {key: 'precio', label: 'Precio'},
  {key: 'flete', label: 'Flete (C/U Flete + Adic. Localidad)'},
  {key: 'ventaFlete', label: 'Venta Flete Full'},
  {key: 'total', label: 'Total a Cobrar'},
  {key: 'comision', label: 'Comision Productos'},
  {key: 'comisionFlete', label: 'Comision Flete'},
  {key: 'orga', label: 'Orga'},
  {key: 'comentariosVentas', label: 'Comentarios'},
  {key: 'repartidor', label: 'Repartidor'},
  {key: 'fLiquidar', label: 'Flete a Liquidar'},
  {key: 'costo', label: 'Costo'},
  {key: 'costoTotal', label: 'Costo Total'},
  {key: 'categoria', label: 'Categoria'},
  {key: 'subCategoria', label: 'SubCategoria'},
  {key: 'lista', label: 'Lista'},
];

export function getDataExport(rows:any[], userType: string) {
  let dataBody: any[] = [];
  
  let data = [...rows];
  userType==='deposit' && (data = data.filter((row: any)=>row.zone===5))

  data.map((row:any) => (row.nroVenta && 
    row.product.map((product:any,key:number) => {
      let productQuantity = row.productCar.find((pc:any)=>pc.idProduct===product.id).quantity;
      let productPrice = row.productCar.find((pc:any)=>pc.idProduct===product.id).price || (row.client.typeclient===1 ? product.retailPrice : product.fullPrice);
      let productSellerComission = row.productCar.find((pc:any)=>pc.idProduct===product.id).sellerComission || (row.client.typeclient===1 ? product.sellerCommissionRetail : product.sellerCommissionFull);
      let productCollaboratorComission = row.productCar.find((pc:any)=>pc.idProduct===product.id).collaboratorComission || (row.client.typeclient===1 ? product.collaboratorCommissionRetail : product.collaboratorCommissionFull);
      let productCost = row.productCar.find((pc:any)=>pc.idProduct===product.id).cost || product.cost;
      if(row.seller.idProfile===5) row.collaborator.color = 15;
      dataBody.push(
      {
        fechaVenta:format(new Date(row.saledate), 'dd/MM/yyyy'),
        numero:row.nroVenta, 
        color: colorOptions.find((x) => x.value === row.collaborator.color)?.label || row.collaborator.color, 
        vendedor:`${row.seller.firstName} ${row.seller.lastName}`.toUpperCase(),
        zona:decode_utf8(zoneOptions.find((x) => x.value === row.zone)?.label.toUpperCase()) || 'Desconocido'.toUpperCase(),
        localidad:decode_utf8(row.client.locality.toUpperCase()),
        cliente:decode_utf8(`${row.client.firstname} ${row.client.lastname}`.toUpperCase()),
        dni:row.client.dni,
        telefono:row.client.phone,
        direccion:decode_utf8(row.client.address),
        eCalles:decode_utf8(row.client.beetwenstreets?.toUpperCase()),
        sku:product.sku,
        modelo:decode_utf8(product.name?.toUpperCase()),
        cantidad: productQuantity,
        precio: productPrice,
        flete:key===0 ? row.deliverycost : 0,
        ventaFlete:key===0 ? row.deliverycost : 0,
        total:( productQuantity*(productPrice)) + (key===0 ? row.deliverycost : 0),
        comision: productQuantity*productSellerComission,
        comisionFlete: 0,
        orga: productQuantity*productCollaboratorComission,
        comentariosVentas:decode_utf8(row.client?.comments?.toUpperCase()),
        repartidor:row?.deliveryMan?.firstName
        ? `${row.deliveryMan.firstName} ${row.deliveryMan.lastName}`.toUpperCase()
        : 'Sin asignar'.toUpperCase(),
        fLiquidar:'',
        costo:productCost,
        costoTotal:productCost*productQuantity,
        categoria:decode_utf8(product.nameCategory?.toUpperCase()),
        subCategoria:decode_utf8(product.nameSubCategory?.toUpperCase()),
        lista: buyerTypeOptions.find(x => x.value === row.client?.typeclient)?.label
      })
    }
  )));
  return dataBody;
}

export const headerExportColaborador: any[] = [
  {key: 'fechaVenta', label: 'Fecha de Venta'},
  {key: 'numero', label: 'Número'},
  {key: 'color', label: 'Grupo'},
  {key: 'vendedor', label: 'Vendedor'},
  {key: 'zona', label: 'Zona'},
  {key: 'localidad', label: 'Localidad'},
  {key: 'cliente', label: 'Cliente'},
  {key: 'dni', label: 'DNI'},
  {key: 'telefono', label: 'Telefono'},
  {key: 'direccion', label: 'Direccion'},
  {key: 'eCalles', label: 'E/Calles'},
  {key: 'sku', label: 'SKU'},
  {key: 'modelo', label: 'Modelo'},
  {key: 'cantidad', label: 'Cant'},
  {key: 'precio', label: 'Precio'},
  {key: 'flete', label: 'Flete'},
  {key: 'total', label: 'Total a Cobrar'},
  {key: 'comision', label: 'Comision'},
  {key: 'orga', label: 'Orga'},
  {key: 'comentariosVentas', label: 'Comentarios'},
  {key: 'repartidor', label: 'Repartidor'},
  {key: 'fLiquidar', label: 'Flete a Liquidar'},
  {key: 'categoria', label: 'Categoria'},
  {key: 'subCategoria', label: 'SubCategoria'},
  {key: 'lista', label: 'Lista'},
];

export function getDataExportColaborador(rows:any[]) {
  let dataBody: any[] = [];

  rows.map((row:any) => (row.nroVenta && 
    row.product.map((product:any,key:number) => {
      let productQuantity = row.productCar.find((pc:any)=>pc.idProduct===product.id).quantity;
      let productPrice = row.productCar.find((pc:any)=>pc.idProduct===product.id).price || (row.client.typeclient===1 ? product.retailPrice : product.fullPrice);
      let productSellerComission = row.productCar.find((pc:any)=>pc.idProduct===product.id).sellerComission || (row.client.typeclient===1 ? product.sellerCommissionRetail : product.sellerCommissionFull);
      let productCollaboratorComission = row.productCar.find((pc:any)=>pc.idProduct===product.id).collaboratorComission || (row.client.typeclient===1 ? product.collaboratorCommissionRetail : product.collaboratorCommissionFull);
      dataBody.push(
      {
        fechaVenta:format(new Date(row.saledate), 'dd/MM/yyyy'),
        numero:row.nroVenta, 
        color: colorOptions.find((x) => x.value === row.collaborator.color)?.label || row.collaborator.color, 
        vendedor:decode_utf8(`${row.seller.firstName} ${row.seller.lastName}`.toUpperCase()),
        zona:decode_utf8(zoneOptions.find((x) => x.value === row.zone)?.label.toUpperCase()) || 'Desconocido'.toUpperCase(),
        localidad:decode_utf8(row.client.locality.toUpperCase()),
        cliente:decode_utf8(`${row.client.firstname} ${row.client.lastname}`.toUpperCase()),
        dni:row.client.dni,
        telefono:row.client.phone,
        direccion:decode_utf8(row.client.address),
        eCalles:decode_utf8(row.client.beetwenstreets?.toUpperCase()),
        sku:product.sku,
        modelo:decode_utf8(product.name?.toUpperCase()),
        cantidad: productQuantity,
        precio: productPrice,
        flete:key===0 ? row.deliverycost : 0,
        total:(productQuantity*(productPrice)) + (key===0 ? row.deliverycost : 0),
        comision: productQuantity*productSellerComission,
        orga: productQuantity*productCollaboratorComission,
        comentariosVentas:decode_utf8(row.client?.comments?.toUpperCase()),
        repartidor:row?.deliveryMan?.firstName
        ? `${row.deliveryMan.firstName} ${row.deliveryMan.lastName}`.toUpperCase()
        : 'Sin asignar'.toUpperCase(),
        fLiquidar:'',
        categoria:decode_utf8(product.nameCategory?.toUpperCase()),
        subCategoria:decode_utf8(product.nameSubCategory?.toUpperCase()),
        lista: row.saletype,
      })
    }
  )));
  return dataBody;
}